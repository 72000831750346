import {IF2PGameConfigAPI, UrlsConfig} from "../index";
import {AppIdentifiers, IDivHostedAppInitParameters} from "@sparkware/uc-sdk-core";
import {getConfigForSubBrand} from "../util/getConfigForSubBrand";
import gameMap from "../../resources/prod/map.json" assert {type: "json"};
import tAndCLinks from "../../resources/prod/tAndCLinks.json" assert {type: "json"};
import {loadUrls} from "../util/loadUrls";
import objectAssignDeep from "object-assign-deep";
import {getRestrictions} from "../util/getRestrictions";
import {getSupportedLanguages} from "../util/getSupportedLanguages";
import {getSupportedCurrencies} from "../util/getSupportedCurrencies";

export class ProdConfigApi implements IF2PGameConfigAPI {
    gameUrl = null
    containerID = null
    wheelInfo = null
    subBrandID = null
    orbitData = null

    public init(orbitData: IDivHostedAppInitParameters) {
        if (!orbitData) {
            throw new Error("No orbit data on init, cannot continue!");
        }

        this.orbitData = orbitData;
        this.subBrandID = orbitData?.applicationContext?.subBrandID;
        this.wheelInfo = getConfigForSubBrand(this.subBrandID, gameMap);
        this.gameUrl = this.wheelInfo.url;
        this.containerID = orbitData.applicationContext.launchInfo.containerID;

        if (!this.gameUrl) {
            if (orbitData.messageBrokerChannels?.session?.topics?.appError) {
                orbitData.messageBrokerChannels?.session?.topics?.appError.publish({
                    publisher: AppIdentifiers.CasinoBonusGameWidget.toString()
                }, {
                    appID: AppIdentifiers.CasinoBonusGameWidget, // docs say appType, f2p engine implementation says appID...
                    appType: AppIdentifiers.CasinoBonusGameWidget,
                    correlationID: orbitData.correlationID,
                    errorCode: "",// ?string;
                    errorDescription: `Missing config for subBrandID ${this.subBrandID}`,
                    launchInfo: orbitData.applicationContext?.launchInfo
                });
            }
            throw new Error(`Missing config for subBrandID ${this.subBrandID}`);
        }
    }
    public async loadUrls(urlsRoot:string, baseUrl:string) {
        const urls:UrlsConfig = await loadUrls(urlsRoot);
        urls.baseUrl = baseUrl;
        this.onGameLoaded(urls);
    }

    private onGameLoaded(urls) {
        // console.log("############## script.src loaded...");
        const subBrandWheelConfig = this.wheelInfo.config ? (this.wheelInfo.config[this.subBrandID] ?? {}) : {};
        const restrictions = getRestrictions(subBrandWheelConfig);
        const supportedLanguages = getSupportedLanguages(subBrandWheelConfig);
        const supportedCurrencies = getSupportedCurrencies(subBrandWheelConfig);
        const fullGameConfig = objectAssignDeep({}, {skins: this.wheelInfo.skins}, {urls: urls}, tAndCLinks, subBrandWheelConfig,
            restrictions, supportedLanguages, supportedCurrencies);
        window["CASINO_BONUS_GAME_WIDGET_gameSrc"].init(this.orbitData, fullGameConfig);
    }
}